.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.start img{
  border: none; 
}

.logo{
  border: 1px solid hsl(0, 0%, 87%);
  width: 100%;
  height: 100%;
}
.logo img {
  height: 70%;
  width: 100%;
}

/* .nav-logo{
  height: 25px;
  border: 2px solid red;
} */

@media (min-width: 640px) {
  .content{
    
    height: 50vh;
  }

  .start{
    /* background: lightgreen; */
    width: 60%;
    padding: 3rem;
  }

  .start img{
    width: 100%;
  }

  .nav a{
    font-size: larger;
  }
  
  .logo{
    width: 300px;
    height: 100%;
    
  }
  .logo img{
    border: 2px solid #ddd;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .round{
    border-radius: 45%;
    padding-top: 30px;
  }
  .ceo{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
  }
  .nav-logo{
    height: 63px;
    border: none;
  }
  .warao{
    width: 50%;
    height: 30%;
  }
  .warao img{
    width: 100%;
    border-radius: 50%;
  }
  .service img{
    height: 100vh;
    object-fit: cover;
  }
}

